<template>

  <div class="container">
		<div class="row" v-if="!edit">

      <div class="col-12 col-lg-9 subnav">
        <router-link to="/hallinta/sopimusasiakkaat"><h1>Sopimusasiakkaat</h1></router-link>
				<router-link to="/hallinta/kohteet"><h1>Kohteet</h1></router-link>
        <router-link to="/hallinta/asukkaat"><h1>Asukkaat</h1></router-link>
        <router-link to="/hallinta/huoltomiehet"><h1>Huoltomiehet</h1></router-link>
			</div>
			<div class="col-12 col-lg-3 text-right">
				<button class="btn btn-new btn-lg d-inline-block" v-if="!loading" v-on:click.stop.prevent="newitem"><i class="fas fa-plus"></i> Luo uusi asukas</button>
			</div>

			<div class="col-12">

				<b-overlay v-if="loading" class="list-loading" variant="white" show no-wrap></b-overlay>

        <div class="filters">
          <div class="filter narrow">
            <select v-model="filters.site_id">
              <option value="">Kohde</option>
              <option v-for="(site) in sites" :key="site.id" :value="site.id">{{ site.name }}</option>
            </select>
          </div>
          <div class="filter">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="inactive" value="1" v-model="filters.inactive">
              <label class="custom-control-label" for="inactive">Näytä vain ei aktivoidut</label>
            </div>
          </div>
          <div class="filter">
            <input type="text" v-model="filters.fullname" class="form-control form-control-sm" placeholder="Hae asukkaan nimellä" />
          </div>
          <button type="button" class="btn btn-sm btn-primary" v-on:click="load()">Suodata</button>
				</div>

				<b-alert variant="danger" :show="error? true : false">{{ error }}</b-alert>

        <table class="admin-list" v-if="!loading && visible > 0">
					<tr>
						<th>ID <i class="fas fa-chevron-down" v-on:click="sort = 'id:asc'" v-if="sort != 'id:asc'"></i> <i class="fas fa-chevron-up" v-on:click="sort = 'id:desc'" v-if="sort == 'id:asc'"></i></th>
						<th>Nimi <i class="fas fa-chevron-down" v-on:click="sort = 'Profile.fullname:asc'" v-if="sort != 'Profile.fullname:asc'"></i> <i class="fas fa-chevron-up" v-on:click="sort = 'Profile.fullname:desc'" v-if="sort == 'Profile.fullname:asc'"></i></th>
						<th>Kohde</th>
						<th>Asuntonro <i class="fas fa-chevron-down" v-on:click="sort = 'Profile.city:asc'" v-if="sort != 'Profile.city:asc'"></i> <i class="fas fa-chevron-up" v-on:click="sort = 'Profile.city:desc'" v-if="sort == 'Profile.city:asc'"></i></th>
						<th>Tila</th>
						<th></th>
					</tr>
					<tr v-for="(item) in items" :key="item.id" :id="['user-' + item.id]">
						<td class="w120"><p><strong>{{ item.id }}</strong></p></td>
						<td class=""><p><strong>{{ item.lastname }}, {{ item.firstname }}</strong></p></td>
            <td class=""><p>{{ getSite(item.sites) }}</p></td>
						<td class=""><p>{{ item.city }}</p></td>
            <td class=""><p><span v-if="item.active" class="green">Aktiivinen</span><span v-if="!item.active" class="red">Ei aktiivinen</span> <a v-if="!item.active" class="btn btn-sm btn-success ml-2" v-on:click.stop.prevent="activate(item.id)">Aktivoi</a></p></td>
						<td class="text-right"><a class="btn btn-sm btn-primary" v-on:click.stop.prevent="edititem(item.id)">Näytä</a></td>
					</tr>
				</table>

				<b-alert class="my-3" variant="info" :show="!loading && items.length == 0? true : false">Käyttäjiä ei löydetty!</b-alert>

				<ul class="pagination pagination-sm justify-content-center my-4" v-if="!loading && pages > 0">
					<li v-for="n in pages" :key="n" class="page-item" :class="[page == (n-1) ? 'active' : '']"><a class="page-link" href="#" v-on:click.stop.prevent="showpage(n-1)">{{ n }}</a></li>
				</ul>

			</div>
		</div>
		<div class="row" v-if="edit">
      <div class="col-12 pt-1">
        <b-overlay variant="white" :show="sending" no-wrap></b-overlay>
				<form @submit.prevent="saveedit">
          <div class="row">
						<div class="col-12 col-lg-6">
              <h1 v-if="typeof edit_data.id == 'undefined'">Uusi asukas</h1>
              <h1 v-if="typeof edit_data.id != 'undefined'">{{ edit_data.lastname }}, {{ edit_data.firstname }} ({{ edit_data.id }})</h1>

              <div class="row">

                <div class="form-group col-lg-6">
                  <label class="form-label">Sukunimi</label>
                  <input type="text" v-model="edit_data.lastname" class="form-control" required />
                </div>

                <div class="form-group col-lg-6">
                  <label class="form-label">Etunimi</label>
                  <input type="text" v-model="edit_data.firstname" class="form-control" required />
                </div>

                <div class="form-group col-lg-6">
                  <label class="form-label">Sähköpostiosoite</label>
                  <input type="email" v-model="edit_data.email" class="form-control" required />
                </div>

                <div class="form-group col-lg-6">
                  <label class="form-label">Puhelin</label>
                  <input type="text" v-model="edit_data.phone" class="form-control" required />
                </div>

                <div class="form-group col-lg-6">
                  <label class="form-label">Rooli:</label>
                  <select class="form-control" v-model="edit_data.fax" required>
                    <option value=""> - valitse rooli - </option>
                    <option>vuokralainen</option>
                    <option>alivuokralainen</option>
                    <option>omistaja</option>
                    <option>isännöitsijä</option>
                  </select>
                </div>

                <div class="form-group col-lg-6">
                  <label class="form-label">Osoite</label>
                  <input type="text" v-model="edit_data.address" class="form-control" required />
                </div>

                <div class="form-group col-lg-6">
                  <label class="form-label">Kohde:</label>
                  <select class="form-control" v-model="edit_data.sites" v-if="0">
                    <option value=""> - valitse kohde - </option>
                    <option v-for="(site) in sites" :key="site.id" :value="site.id">{{ site.name }}</option>
                  </select>
                  <div>
                    <multiselect v-model="edit_data.sites" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" :hide-selected="true" :showLabels="false" placeholder="Valitse kohteet" :options="sites.map(site => site.id)" :custom-label="opt => sites.find(x => x.id == opt).name">
                      <template slot="noResult">Kohteita ei löydetty.</template>
                    </multiselect>
                  </div>
                </div>

                <div class="form-group col-lg-6">
                  <label class="form-label">Asunnon numero</label>
                  <input type="text" v-model="edit_data.city" class="form-control" required />
                </div>

                <div class="form-group col-lg-6" v-if="typeof edit_data.id != 'undefined'">
                  <label class="form-label">Aktiivinen</label>
                  <select v-model="edit_data.active" class="form-control">
                    <option value="true">Kyllä</option>
                    <option value="false">Ei</option>
                  </select>
                </div>

                <div class="form-group col-12">
                  <label class="form-label">Lisätiedot</label>
                  <textarea v-model="edit_data.comment" class="form-control"></textarea>
                </div>

              </div>

              <p v-if="typeof edit_data.id != 'undefined'">
                <span v-if="edit_data.terms == '1'">Hyväksyy käyttöehdot</span><br/>
                Tunnus luotu {{ edit_data.createdon | formatDateTime }}<br/>
                <span v-if="edit_data.active && typeof edit_data.activated_by != 'undefined'">Aktivoinut {{ edit_data.activated_by }} {{ edit_data.activated_on | formatDateTime }}</span>
              </p>

						</div>

            <div class="col-12 col-lg-5 offset-lg-1">
              <div class="form-group text-right">
                <button type="button" class="btn btn-delete" v-if="typeof edit_data.id != 'undefined'" @click="confirmDelete = true"><i class="fas fa-trash"></i> POISTA</button>
                <button type="button" class="btn btn-light btn-lg ml-2" v-if="typeof edit_data.id != 'undefined'" v-on:click="canceledit()">TAKAISIN</button>
                <button type="button" class="btn btn-light btn-lg ml-2" v-if="typeof edit_data.id == 'undefined'" v-on:click="canceledit()">PERUUTA</button>
                <button type="submit" class="btn btn-new btn-lg ml-2"><i class="fas fa-save"></i> TALLENNA</button>
              </div>

              <div class="form-group row mt-5" v-if="typeof edit_data.id == 'undefined'">
                <div class="col-12 check-wrap">
                  <label class="form-label">Hyväksyy käyttöehdot</label>
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="terms" value="1" v-model="edit_data.terms" required>
                    <label class="custom-control-label" for="terms">Hyväksyy käyttöehdot</label>
                  </div>
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="active" value="1" v-model="edit_data.active">
                    <label class="custom-control-label" for="active">Aktivoi käyttäjä ja lähetä tunnukset</label>
                  </div>
                </div>
              </div>

              <div class="form-group mt-5" v-if="typeof edit_data.id != 'undefined'">
                <label class="form-label">Asukkaan tekemät huoltoilmoitukset</label>
                <table class="admin-list">
                  <tr v-for="(ticket) in edit_data.tickets" :key="ticket.id" :id="['user-' + ticket.id]">
                    <td class="w120"><p><strong>{{ ticket.created | formatDateTime }}</strong></p></td>
                    <td class="text"><p>{{ ticket.title }}</p></td>
                    <td class="text-right"><router-link class="btn btn-sm btn-primary" :to="{ name: 'Huoltoilmoitukset', query: { id: ticket.id } }">Näytä</router-link></td>
                  </tr>
                </table>
              </div>

            </div>

					</div>
				</form>

        <b-overlay :show="confirmDelete" variant="white" no-wrap no-center>
          <template #overlay>
            <div ref="dialog" tabindex="-1" role="dialog" aria-modal="false" aria-labelledby="form-confirm-label" class="text-center p-5" >
              <p><strong id="form-confirm-label">Haluatko varmasti poistaa käyttäjän "{{ edit_data.lastname }} {{ edit_data.firstname }}" ?</strong></p>
              <div class="text-center">
                <b-button variant="outline-danger" class="mr-3" @click="confirmDelete = false">Peruuta</b-button>
                <b-button variant="outline-success" @click="senddelete()">OK</b-button>
              </div>
            </div>
          </template>
        </b-overlay>

			</div>
		</div>
	</div>

</template>

<script>
module.exports = {
	data: function () {
		return {
			loading: true,
			error: false,
			items: [],
      sites: [],
      filters: {
        site_id: '',
        fullname: '',
        inactive: false,
      },
			edit: false,
			edit_data: {
				id: 0,
				fullname: '',
				active: false
			},
      confirmDelete: false,
			sending: false,
			visible: 0,
			perpage: 10,
			pages: 0,
			page: 0,
			sort: 'id:desc'
		}
	},
	created: function () {
    var vm = this;
		this.axios.get('/api/sites?limit=500')
		.then(function (response) {
			if(response.status == 200) {
				vm.sites = response.data.results;
			}
		})
		.catch(function (error) {
			console.log(error);
		})
    vm.load();
    if(typeof vm.$route.query.id != 'undefined') {
      if(vm.$route.query.id == 'new') vm.newitem();
			else vm.edititem(this.$route.query.id);
		}
	},
	methods: {
		load: function () {
			var vm = this;
      vm.error = false;
			vm.loading = true;
			var sort = vm.sort.split(':');
			this.axios.get('/api/users?limit=' + vm.perpage + '&start=' + (vm.page * vm.perpage) + '&sort=' + sort[0] + '&dir=' + sort[1] + '&filters=' + JSON.stringify(vm.filters))
			.then(function (response) {
				if(response.status == 200) {
					vm.loading = false;
					vm.pages = Math.ceil(response.data.total / vm.perpage);
					vm.items = response.data.results;
					vm.visible = response.data.results.length;
				} else {
					vm.error = response.data.message;
				}
			})
			.catch(function (error) {
				vm.error = error;
				vm.items = [];
				vm.pages = 0;
				vm.loading = false;
			})
		},
    getSite: function(s_id) {
      if(s_id != null) {
        var vm = this;
        var str = [];
        var tmp = s_id.split(",");
        Object.keys(tmp).forEach(function(key) {
          var find = parseInt(tmp[key]);
          const result = vm.sites.find( ({ id }) => id === find );
          if(result) str.push(result.name);
        });
        if(str.length > 0) return str.join(", ");
      }
      return s_id;
    },
		showpage: function (id) {
			this.page = id;
		},
		edititem: function (id) {
      var vm = this;
			vm.edit = true;
      vm.sending = true;

      this.axios.get('/api/users/' + id)
      .then(function (response) {
        if(response.data.success) {
          vm.edit_data = response.data.object;
          vm.edit_data.firstname  = response.data.object.extended.firstname;
          vm.edit_data.lastname  = response.data.object.extended.lastname;
          vm.edit_data.activated_by = response.data.object.extended.activated_by_name;
          vm.edit_data.activated_on = response.data.object.extended.activated_on;
          vm.sending = false;
        } else {
          vm.$parent.makeToast('danger', 'Asukkaan lataus', 'Epäonnistui!');
          vm.edit = false;
          vm.sending = false;
        }
      })
      .catch(function (error) {
        console.log(error);
        vm.$parent.makeToast('danger', 'Asukkaan lataus', 'Epäonnistui!');
        vm.edit = false;
        vm.sending = false;
      })

		},
		canceledit: function () {
			this.edit = false;
		},
		newitem: function () {
			this.edit = true;
			this.edit_data = { sites: '', fax: '', address: '', info: '', active:false }
		},
		saveedit: function () {
			var vm = this;
			if(!vm.sending) {
				vm.sending = true;
				var method = 'put';
				if(typeof vm.edit_data.id == 'undefined') method = 'post';
				this.axios({
					method: method,
					url: '/api/users',
					data: vm.edit_data
				})
				.then(function (response) {
					if(response.data.success) {
						if(typeof vm.edit_data.id == 'undefined' && response.data.object.id > 0) { 
              vm.edit_data.id = response.data.object.id;
              vm.edit_data.fullname = response.data.object.fullname;
            }

						vm.$parent.makeToast('success', 'Käyttäjän tallennus', 'Onnistui!');
						vm.load();
					} else {
            var tmp = []
						Object.keys(response.data.errors).forEach(function(key){ tmp.push(response.data.errors[key]); });
						vm.$parent.makeToast('warning', 'Käyttäjän tallennus', tmp.join(" ", tmp));
					}
					vm.sending = false;
				})
				.catch(function () {
					vm.sending = false;
					vm.$parent.makeToast('danger', 'Käyttäjän tallennus', 'Epäonnistui!');
				})
			}
		},
    activate: function (uid) {
			var vm = this;
			if(!vm.sending) {
				vm.sending = true;
				var method = 'put';
				this.axios({
					method: method,
					url: '/api/users',
					data: { id: uid, active: true }
				})
				.then(function (response) {
					if(response.data.success) {
						vm.$parent.makeToast('success', 'Käyttäjän aktivointi', 'Onnistui!');
						vm.load();
					} else {
						vm.$parent.makeToast('warning', 'Käyttäjän aktivointi', 'Virhe!');
					}
					vm.sending = false;
				})
				.catch(function () {
					vm.sending = false;
					vm.$parent.makeToast('danger', 'Käyttäjän aktivointi', 'Epäonnistui!');
				})
			}
		},
    senddelete: function () {
			var vm = this;
      vm.sending = true;
      vm.confirmDelete = false;
			this.axios({
				method: 'delete',
				url: '/api/users',
				data: vm.edit_data
			})
			.then(function (response) {
				if(response.data.success) {
					vm.$parent.makeToast('success', 'Käyttäjän poisto', 'Onnistui!');
					vm.load();
					vm.edit = false;
				} else {
					vm.$parent.makeToast('danger', 'Käyttäjän poisto', 'Epäonnistui!');
				}
        vm.sending = false;
			})
			.catch(function () {
				vm.$parent.makeToast('danger', 'Käyttäjän poisto', 'Epäonnistui!');
        vm.sending = false;
			})
    }
	},
	watch: {
		page: function () {
			this.load();
		},
    sort: function () {
			this.load();
		},
	}
}
</script>
